import { FC, Fragment, memo } from 'react';
import classNames from 'classnames';
import chunk from 'lodash/chunk';
import { SwiperProps, SwiperSlide } from 'swiper/react';

import { Carousel } from 'components/common/Carousel';
import { ContentLoader } from 'components/design-system/ContentLoader';
import { useViewport } from 'context/viewport';
import { Listing, StyledComponent } from 'types';

import { ListingCard } from './ListingCard';

interface ListingCarouselProps extends StyledComponent {
  items: Listing[];
  title: string | React.ReactNode;
  numberOfRows?: number;
  loading?: boolean;
}

const placeholders = Array.from(new Array(10)).map((_, i) => ({ id: i }));
const LOADING_HEIGHT_MOBILE = '280px';
const LOADING_HEIGHT_DESKTOP = '293px';

export const ListingCarousel: FC<ListingCarouselProps> = memo(
  ({ items, title, className, numberOfRows = 2, loading }) => {
    const listings = items || placeholders;
    const { isMobileSmall } = useViewport();
    const isOneRow = listings.length < 5 || isMobileSmall;
    const listingChunks = chunk(listings, isOneRow ? 1 : numberOfRows);

    const heightListingCardClassName = isOneRow ? 'h-100' : `h-${Math.floor(100 / numberOfRows)}`;

    const swiperProps: SwiperProps = {
      className: 'px-4 pt-3 pb-4 w-100',
      watchSlidesProgress: !isMobileSmall
    };

    return (
      <Carousel
        title={title}
        className={className}
        swiperProps={swiperProps}
        slidesPerView={isMobileSmall ? 'auto' : 4}
        showArrowGradient={false}
      >
        {() =>
          listingChunks.map((listingChunk, idx) => (
            <SwiperSlide key={`listing-chunk-${idx}`} className={classNames(isMobileSmall && 'w-60')}>
              <div className='d-flex flex-column justify-content-between gap-3 h-100'>
                {listingChunk.map(listing => (
                  <Fragment key={listing.id}>
                    {loading ? (
                      <ContentLoader
                        borderRadius={20}
                        height={isMobileSmall ? LOADING_HEIGHT_MOBILE : LOADING_HEIGHT_DESKTOP}
                      />
                    ) : (
                      <ListingCard key={listing.id} listing={listing} className={heightListingCardClassName} />
                    )}
                  </Fragment>
                ))}
                {listingChunk.length === 1 && !isMobileSmall && <div className='h-50' />}
              </div>
            </SwiperSlide>
          ))
        }
      </Carousel>
    );
  }
);
